import React, { useEffect, useState } from 'react';
import locale from 'antd/locale/pl_PL';
import './App.css';
import TransactionsPage from './components/Dashboard/TransactionsPage';
import AccountPage from './components/Dashboard/AccountPage';
import { Routes, Link, Route } from 'react-router-dom';
import {
  Layout,
  Menu,
  ConfigProvider,
  Button,
  Dropdown
} from 'antd';
import TransactionPage from './components/Transaction/TransactionPage';
import TransactionsAdminPage from './components/Dashboard/TransactionsAdminPage';
import BankAccountsPage from "./components/Dashboard/BankAccountsPage";
import WelcomePage from './components/Offer/WelcomePage';
import RegisterPage from './components/Register/RegisterPage';
import PricingPage from './components/Offer/PricingPage';
import JobPage from './components/Offer/JobPage';
import FeaturesPage from './components/Offer/FeaturesPage';
import CreateTransactionPage from './components/Offer/CreateTransactionPage';
import AboutPage from './components/Offer/AboutPage';
import SocialResponsibilityPage from './components/Offer/SocialResponsibilityPage';
import PasswordResetPage from './components/Login/PasswordResetPage';
import AcceptInvitationPage from './components/Login/AcceptInvitationPage';
import AccountDataPage from './components/Dashboard/Account/Data/AccountDataPage';
import ContactPage from './components/Offer/ContactPage';
import RegulationsPage from './components/Offer/RegulationsPage';
import AccountCategoriesPage from './components/Dashboard/Account/Offer/AccountCategoriesPage';
import AccountProductsCategoriesPage from './components/Dashboard/Account/Offer/AccountProductsCategoriesPage';
import AccountProductPage from './components/Dashboard/Account/Offer/AccountProductPage';
import { HelmetProvider } from 'react-helmet-async';
const { Content, Header, Footer } = Layout;


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('authToken'));
  const [isRootPath, setIsRootPath] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    setIsLoggedIn(false);
  }

  useEffect(() => {
    setIsRootPath(window.location.pathname === "/");
  });

  const menu = (
    <Menu>
      <Menu.Item key="4">
        <Link to="/account">Moje dane</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/" onClick={handleLogout}>
          Wyloguj
        </Link>
      </Menu.Item>
    </Menu>
  );


  return (
    <HelmetProvider>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#EF20A9',
            borderRadius: '10px',
            fontFamily: 'Roboto Flex'
          },
        }}
      >

        <Layout>

          {isLoggedIn ? (
            <Header style={{ background: "#061641" }}>
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ display: 'flex', background: "transparent" }}>
                <div className="logo"><Link to="/transactions"><h2 className='brand'>POSTPAY</h2></Link></div>
                <Menu.Item key="1">
                  <Link to="/transactions">Transakcje</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      Konto
                    </a>
                  </Dropdown>
                </Menu.Item>
              </Menu>
            </Header>
          ) : <Header style={{ background: "#061641" }}>
            <Menu theme="dark" mode="horizontal" style={{ display: 'flex', background: "transparent" }}>
              <div className="logo"><Link to="/"><h2 className='brand'>POSTPAY</h2></Link></div>
              <Menu.Item style={{ background: "transparent", right: "141px", position: "absolute", zIndex: 1000 }} key="3">
                <Link to="/pricing">
                  Cennik
                </Link>
              </Menu.Item>
              <Menu.Item style={{ background: "transparent", right: "11px", position: "absolute", zIndex: 1000 }} key="3">
                <Link to="/transactions">
                  <Button size='middle'>Mój Postpay</Button>
                </Link>
              </Menu.Item>
            </Menu>
          </Header>
          }

          <Content>
            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route path="/create-transaction" element={<CreateTransactionPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/features" element={<FeaturesPage />} />
              <Route path="/job" element={<JobPage />} />
              <Route path="/regulations" element={<RegulationsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/social-responsibility" element={<SocialResponsibilityPage />} />
              <Route path="/transactions" element={<TransactionsPage />} />
              <Route path="/admin/transactions" element={<TransactionsAdminPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/account/data" element={<AccountDataPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/account/register" element={<RegisterPage />} />
              <Route path="/account/password-reset" element={<PasswordResetPage />} />
              <Route path="/account/accept-invitation/:invitationUuid" element={<AcceptInvitationPage />} />
              <Route path="/account/bank-accounts" element={<BankAccountsPage />} />
              <Route path="/account/offer" element={<AccountCategoriesPage />} />
              <Route path="/account/offer/category/:categoryUuid" element={<AccountProductsCategoriesPage />} />
              <Route path="/account/offer/product/:productUuid" element={<AccountProductPage />} />
              <Route path="/transaction/:transactionUuid" element={<TransactionPage />} />
            </Routes>
          </Content>

        </Layout>
      </ConfigProvider >
    </HelmetProvider>
  );
};

export default App;